<template>
  <div>
    <span :class="{'is-send':isSend+''==='true'}" class="send-code-btn" @click="sendSms(1)" v-html="codeText" />
    <code-button ref="codeBtn" :unique-key="uniqueKey" @isSend="send=>isSend=send" @end="codeEnd" @start="codeStart" @change="codeChange" />

    <robot-img ref="robotImg" :mobile="mobile" @finishCode="finishCode" @validateSuccess="isValidateImg=>sendSms(codeType,isValidateImg)" />
  </div>
</template>

<script>
import CodeButton from '@/components/CodeButton'
import RobotImg from '@/components/RobotImg'

import { sendSms, checkNewsCode } from '@/api/axios/index'
export default {
  components: { CodeButton, RobotImg },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    mobile: {
      type: [String, Number],
      default: ''
    },
    uniqueKey: {
      type: String,
      default: () => {
        return localStorage.getItem('mobile') || 'first'
      }
    },
    // 1登录 2注册 3忘记密码
    moduleType: {
      type: [String, Number],
      default: 'sms_login'
    }
  },
  data() {
    return {
      isSend: sessionStorage.getItem(this.uniqueKey + 'isSend'),
      codeText: '',
      codeType: '1',
      code: '',
      imageCode: '',
      dataForm: {
        mobile: this.mobile,
        password: ''
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.code = val
      },
      immediate: true
    }
  },

  methods: {
    codeChange(text) {
      this.codeText = text
    },

    // 倒计时开始
    codeStart() {
      this.isSend = true
      this.$toast.success(this.$t('login.codeSended'))
      sessionStorage.setItem(this.uniqueKey + 'isSend', this.isSend)
    },
    // 倒计时结束
    codeEnd() {
      this.isSend = false
      sessionStorage.setItem(this.uniqueKey + 'isSend', this.isSend)
    },

    // 发送验证码 codeType 1:短信验证码 2:语音验证码
    async sendSms(codeType, isValidateImg) {
      if (this.sendTips(codeType)) return
      this.codeType = codeType
      const params = { mobile_phone_number: this.mobile, Umusango_Ibumba: this.moduleType, Ukufisangilako_code: codeType }

      const { data, msg } = await sendSms(params, isValidateImg) || {}
      if (data + '' === '1') this.$refs.robotImg.getCaptcha(msg)
      if (data + '' === 'isSend') {
        this.$refs.codeBtn.start()
        if (this.imageCode) this.$refs.robotImg.$refs.baseDialog.visible = false
        if (codeType + '' === '2') {
          this.$toast.success(this.$t('login.tabVoiceSend'))
        }
      }
    },

    sendTips(codeType) {
      if (this.isSend + '' === 'true') {
        // 短信已发送，请注意查看信息
        if (this.codeType + '' === '1' && codeType + '' === '2') this.$toast.fail(this.$t('login.tabSmsSend'))
        // 语音已发送，请注意接听来电
        if (this.codeType + '' === '2' && codeType + '' === '2') this.$toast.fail(this.$t('login.tabVoiceSend'))
      }
      return this.isSend + '' === 'true'
    },

    // 校验验证码
    async checkNewsCode(codes) {
      const params = { mobile: this.mobile, verify_code: codes, sms_mode: this.codeType, sms_type: this.moduleType }

      try {
        const { inyimbo } = await checkNewsCode(params) || {}
        return inyimbo + '' === '200'
      } catch (err) {
        console.log(err)
      }
    },

    // 获取输入的图形验证码
    finishCode(val) {
      this.imageCode = val
      this.sendSms(this.codeType)
    }
  }
}
</script>
<style lang="scss" scoped>

.send-code-btn{
  display: block;
  // width: 160px;
  min-width: 80px;
  height: 100px;
  line-height: 100px;
  // background: #1761C5FF;
  border-radius: 16px;
  text-align: right;
  font-size: 26px;
  color: #0038ff;
  padding-right: 30px;
  position: relative;
}
.is-send.send-code-btn{
   color: #999999;
}
</style>

<style>
/* .send-code-btn .refresh{
  position: relative;
} */
.send-code-btn .refresh::after{
  content:'';
  width: 80px;
  height: 80px;
  position: absolute;
  right: 24px;
  top: 24px;
  background: url('~@/assets/images/login/refresh-icon.png') no-repeat center center;
  background-size: 100%;
}
</style>
