<template>
  <div class="code-input-main">
    <ul class="code-input-box is-line clearfix">
      <li
        v-for="(item, index) in codeLength"
        :key="index"
        class="code-input-item"
      >
        <span v-if="dot && codeArray.length > index" class="code-input-item-dot code-text-dot" />
        <span v-else class="code-text">{{ codeArray[index] }}</span>
        <span v-if="isFocus && codeArray.length === index" :style="{backgroundColor: color}" class="code-input-item-cursor"></span>
      </li>
    </ul>
    <div class="code-button">
      <slot name="button"></slot>
    </div>
    <input
      ref="strname"
      type="tel"
      :value="inputValue"
      :maxlength="maxlength"
      class="code-input"
      :style="{}"
      @input="inputHandler"
      @focus="focusEvent"
      @blur="blurEvent"
    >
  </div>
</template>

<script>
export default {
  props: {
    // 是否自动获取焦点
    focus: {
      type: Boolean,
      default: true
    },
    dot: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 4
    },
    // 预置值
    value: {
      type: [String, Number],
      default: ''
    },
    // 是否禁止输入"."符号
    disabledDot: {
      type: Boolean,
      default: false
    },
    // 字体颜色
    color: {
      type: String,
      default: '#000'
    }
  },
  data() {
    return {
      inputValue: '',
      isFocus: this.focus
    }
  },
  computed: {
    // 根据长度，循环输入框的个数，因为头条小程序数值不能用于v-for
    codeLength() {
      return new Array(Number(this.maxlength))
    },
    // 将输入的值，转为数组，给item历遍时，根据当前的索引显示数组的元素
    codeArray() {
      return String(this.inputValue).split('')
    },
    // 下划线模式下，横线的样式
    lineStyle() {
      const style = {}
      style.height = this.hairline ? '2px' : '4px'
      style.width = '20px'
      // 线条模式下，背景色即为边框颜色
      style.backgroundColor = this.borderColor
      return style
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        // 转为字符串，超出部分截掉
        this.inputValue = String(val).substring(0, this.maxlength)
      }
    }
  },
  mounted() {
    this.getFocus()
  },
  methods: {
    // 监听输入框的值发生变化
    inputHandler(e) {
      const value = e.target.value
      this.inputValue = value
      // 是否允许输入“.”符号
      if (this.disabledDot) {
        this.$nextTick(() => {
          this.inputValue = value.replace('.', '')
        })
      }
      // 未达到maxlength之前，发送change事件，达到后发送finish事件
      this.$emit('change', value)
      // 修改通过v-model双向绑定的值
      this.$emit('input', value)
      // 达到用户指定输入长度时，发出完成事件
      if (String(value).length >= Number(this.maxlength)) {
        this.$emit('finish', value)
      }
    },
    getFocus() {
      this.$nextTick(function() {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.$refs.strname.focus()
        }, 500)
      })
    },
    focusEvent() {
      this.isFocus = true
      this.$emit('focus')
    },
    blurEvent(){
      this.isFocus = false
      this.$emit('blur')
    }
  }

}
</script>
<style lang="scss" scoped>
.code-input-box{
  .code-input-item{
    float: left;
    width: 59px;
    height: 59px;
    line-height: 59px;
    border: 3px solid #E7E7E7;
    text-align: center;
    margin-right: 52px;
    position: relative;
    font-size: 30px;
    &:last-child{
      margin-right: 0;
    }
  }
}
 .is-line{
  .code-input-item{
    border: none;
    border-bottom:  3px solid #E7E7E7;
  }
}

.code-input-main{
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.code-input{
  height: 59px;
  border: 1px solid black;
  width: 100%;
  position: absolute;
  top: -0;
  left: 0;
  opacity: 0;
}
.code-button{
  position: relative;
  z-index: 10;
}
.code-input-item-cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 1px;
    height: 40%;
    -webkit-animation: 1s cursor-flicker infinite;
    animation: 1s cursor-flicker infinite;
}
@keyframes cursor-flicker {
  0% {opacity: 0;	}
  50% {opacity: 1;}
  100% { opacity: 0;}
}
</style>
