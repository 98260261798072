<template>
  <div>
    <base-dialog
      v-if="dialogVisible"
      ref="baseDialog"
      :confirm-button-text="$t('public.confirmBtn')"
      is-control
      :class="{'is-click':imageCode.length===4}"
      @confirm="confirm"
      @closed="dialogVisible = false"
    >
      <template #node-slot>
        <img class="image-code" :src="phoneImag" alt="" @click="getCaptcha()">
        <p class="no-see">{{ $t('login.invisibility')[2] }}</p>
        <!-- <van-field v-model="imageCode" :maxlength="maxlength" :class="{'empty':!imageCode.length}" :placeholder="$t('login.inputImgCode')" class="image-code-input" type="digit" /> -->
        <code-input v-model="imageCode" :class="{'error-input':errorMsg}" class="input-image-code" @focus="errorMsg = ''" />
        <div class="text-box">
          <div class="error-box">{{ errorMsg }}</div>
          <div class="refresh" @click="getCaptcha()">{{ $t('login.invisibility')[0] }}</div>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import CodeInput from '@/components/CodeInput'
import { getCaptcha, checkCaptcha } from '@/api/axios/index'
export default {
  components: { CodeInput },
  props: {
    mobile: {
      type: [Number, String],
      default: ''
    },
    maxlength: {
      type: [Number, String],
      default: '4'
    }
  },
  data() {
    return {
      dialogVisible: false,
      phoneImag: '',
      imageCode: '',
      errorMsg: ''
    }
  },

  methods: {
    // 提交验证图形验证码
    confirm() {
      if (this.imageCode.length !== 4) return
      // this.$emit('finishCode', this.imageCode)
      this.checkCaptcha()
    },

    // 获取图形码
    async getCaptcha(errorMsg) {
      this.errorMsg = errorMsg
      this.imageCode = ''
      const data = await getCaptcha({ mobile_phone_number: this.mobile })
      this.phoneImag = data
      if (this.dialogVisible) return
      this.showImageValidate()
    },

    // 校验图形验证
    async checkCaptcha() {
      try {
        await checkCaptcha({ mobile_phone_number: this.mobile, inyimbo: this.imageCode }) || {}
        this.$refs.baseDialog.visible = false
        this.$emit('validateSuccess', '1')
      } catch (err) {
        this.getCaptcha()
      }
    },

    // 展示弹框验证
    showImageValidate() {
      this.dialogVisible = true
      this.imageCode = ''
      this.$nextTick(() => {
        this.$refs.baseDialog.init()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.image-code{
  width: 360px;
  height: 120px;
}
.image-code-input{
  // width: 518px;
  height: 116px;
  background: #FFFFFF;
  border-radius: 16px;
  border: 3px solid #D8D8D8;
  margin-bottom: 20px;
}
.input-image-code{
  height: 116px;
  margin-bottom: 20px;
}
:deep(.input-image-code .code-input){
  opacity: 0;
  left: -200px;
  right: 0;
  width: auto;
}
:deep(.input-image-code .code-input-box){
  display: block;
  .code-input-item{
    width: 118px;
    height: 128px;
    line-height: 128px;
    background: #F2F5F6;
    border-radius: 24px;
    margin-right: 23px;
    font-size: 48px;
    font-weight: 500;
    color: #292A4D;
    &:last-child{
      margin-right: 0;
    }
  }
}
:deep(.van-hairline--top::after){
  display: none;
}
.empty{
  position: relative;
}

.empty::before{
  display: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid red;
  border-radius: 16px;
  -webkit-animation: 1.5s cursor-flicker infinite;
  animation: 1.5s cursor-flicker infinite;
}

@keyframes cursor-flicker {
  0% {opacity: 0;	}
  50% {opacity: 1;}
  100% { opacity: 0;}
}

:deep(.van-dialog){
  width: 670px;
  height: 688px;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 64px;
}
:deep(.van-dialog__content){
  padding:0;
}

.no-see{
  font-size: 28px;
  color: #292A4D;
  line-height: 40px;
  padding-top: 48px;
  padding-bottom: 32px;
}
.text-box{
  display: flex;
  padding-bottom: 60px;
  div{
    flex:1;
  }
  .error-box{
    flex:1.5;
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FF6905;
    line-height: 28px;
    text-align: left;
  }
  .refresh{
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #3D5CFF;
    line-height: 28px;
    text-align: right;
    display: none;
  }
}

:deep(.van-dialog__footer .van-button){
  height: 128px;
  background: #B8B8D2FF;
  border-radius: 32px;
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  border-radius: 32px;
}

:deep(.is-click .van-dialog__footer .van-button){
  background: #1C1D21;
}
:deep(.error-input .code-input-item){
  border-color: #FF6905FF!important;
}
</style>
